<template>
  <div class="rightSide">
    <div class="rightSide_inner">
      <div class="rightSide_inner_title">
        <p style="font-size: 30px; color: #333333">{{ $t("Order Details") }}</p>
      </div>

      <div class="rightSide_inner_header">
        <div class="rightSide_inner_header_content">
          <div class="rightSide_order">
            <p>{{ $t("Order Number") }}：</p>
            <p>{{orderInfo.order_sn}}</p>
          </div>
          <div class="rightSide_order">
            <p>{{ $t("Time") }}：</p>
            <p>{{orderInfo.created_at | formatDateStr}}</p>
          </div>
        </div>
      </div>
      <p style="font-size: 22px; color: #333333; margin-top: 20px">
        {{ $t("Item Information") }}
      </p>

      <div class="table_style">
        <el-table
          ref="multipleTable"
          header-align="center"
          border
          class="main-table"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%; font-size: 18px; color: #515a6e"
          :header-cell-style="{ color: 'black' }"
        >
          <el-table-column :label="$t('Picture')" prop="img" align="center">
            <template slot-scope="scope">
              <img
                :src="`http://hwimg.xmvogue.com/thumb/${scope.row.goods_item}.jpg?x-oss-process=style/440`"
                class="mr-3"
                height="80"
                width="80"
                alt="..."
              />
            </template>
          </el-table-column>

          <el-table-column prop="item_en" :label="$t('Name')" align="center" />
          <el-table-column
            prop="goods_item"
            :label="$t('Product Barcode')"
            align="center"
          />
          <el-table-column
            prop="goods_number"
            :label="$t('Qty')"
            align="center"
          />

          <el-table-column :label="$t('Purchase Price')" align="center">
            <template slot-scope="scope">
              ￥{{ scope.row.goods_price|toFixed2 }}
            </template>
          </el-table-column>

          <el-table-column :label="$t('Subtotal')" align="center">
            <template slot-scope="scope">
              ￥{{ scope.row.goods_total|toFixed2 }}
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="rightSide_total">
        <p style="font-size: 16px; color: #333333">{{ $t("Total") }}：</p>
        <p style="font-size: 30px; color: #218da0">￥{{ orderTotal|toFixed2 }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { GetOrdeGoodsById, GetOrderInfo } from "@/api/order/order.js";
import common from "@/common/mixins/common.js";

export default {
  components: {},
  mixins: [common],
  data() {
    return {
      orderInfo: {},
      tableData: [],
      orderTotal: 0,
      power: false,
    };
  },
  created() {
    this.getOrderInfo();
  },
  watch: {
    tableData() {
      this.getOrderTotal();
    },
    power() {
      if (this.power) {
        this.getData();
      }
    },
  },
  methods: {
    getOrderInfo() {
      let id = parseInt(this.$route.query.id);
      GetOrderInfo(id)
        .then((res) => {
          this.orderInfo = res.data.data;
          this.power = true;
        })
        .catch(() => {
          this.power = false;
        });
    },
    //获取订单总价
    getOrderTotal() {
      let total = 0;
      for (let i = 0; i < this.tableData.length; i++) {
        total += this.tableData[i].goods_total;
      }
      this.orderTotal = total;
    },
    //获取参数
    getData() {
      let id = this.$route.query.id;
      GetOrdeGoodsById(id).then((res) => {
        this.tableData = res.data.data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.rightSide {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 1320px;
  height: 786px;
  overflow: scroll;
  margin: 15px 0 0 15px;
  background-color: #ffffff;
  &_inner {
    width: 1218px;
    &_title {
      width: 1218px;
      height: 68px;
      padding-top: 20px;
    }
    &_header {
      width: 1218px;
      height: 58px;
      border-bottom: 1px solid #c0c0cc;
      margin-top: 15px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &_content {
        width: 1218px;
        height: 27px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  &_order {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 18px;
    color: #333333;
  }

  &_total {
    width: 1218px;
    height: 43px;
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.table_style {
  width: 1218px;
  margin-top: 30px;
}
</style>
<style lang="scss">
.footer .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #218da0; //修改后的背景图颜色
  color: #fff;
}
</style>
